
#article_top_div {
    padding: 2vw 0 0 0;
}

    #article_top_div > a {
        display: block;
        width: 100%;
        text-align: right;
    }

.article_item_div {
    position: relative;
    margin: 0 0 2vw 0;
}

    .article_item_div img {
        display: block;
        max-width: 100%;
        padding: 2vw;
        margin: 0 auto 0 auto;
    }

    .article_item_div .article_item_cover_div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 1;
    }

    .article_item_div .article_item_text_div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -25%);
        font-family: 'NotoSansKR';
        font-size: 2.5vw;
        font-weight: bold;
        text-align: center;
        color: white;
        z-index: 2;
        text-align: center;
        white-space: pre;
        text-shadow: 2px 2px 2px black;
        /* padding: 0 10% 0 10%; */
    }

@media only screen and (max-width: 767px) {

    #article_top_div {
        padding: 0;
    }

    .article_item_div {
        position: relative;
        margin: 0 0 4vw 0;
    }

        .article_item_div img {
            display: block;
            max-width: 90%;
            padding: 2vw;
            margin: 0 auto 0 auto;
        }

        .article_item_div .article_item_text_div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 0);
            font-family: 'NotoSansKR';
            font-size: 4.5vw;
            font-weight: bold;
            text-align: center;
            color: white;
            z-index: 2;
            text-align: center;
            white-space: pre;
            text-shadow: 2px 2px 2px black;
            /* padding: 0 10% 0 10%; */
        }
}
