
.navbar_div {
    background-color: white;
}

    .navbar_div a {
        font-size: large;
    }

    .navbar_div svg {   /*  for icons   */
        width: 1.2em;
        height: 1.2em;
    }

    .navbar_div .desk_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .navbar_div .desk_nav .navbar_logo_div {
            text-align: left;
            flex-grow: 1;
        }
        
        .navbar_div .desk_nav .navbar_logo_div img {
            max-width: 55%;
        }

        .navbar_div .desk_nav .navbar_menu_div {
            /* flex-grow: 1; */
            flex-grow: 2;   /*  changed after node.js V20.0 due to changed logo svg format. */
        }

            .navbar_div .desk_nav .navbar_menu_div ul {
                display: block;
                text-align: right;
            }

    .navbar_div .mobile_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 0 4vw; */
    }

        .navbar_div .mobile_nav .navbar_logo_div {
            text-align: left;
        }

            .navbar_div .mobile_nav .navbar_logo_div img {
                max-width: 50%;
            }

@media only screen and (max-width: 767px) {

.navbar_div {
    background-color: white;
    box-shadow: 0 1px 2px darkgrey;
}

}