
.stores_div {
    text-align: center;
}

    .stores_div p {
        margin: 5vh 0 0 0;
    }

    .stores_div img {
        max-width: 50%;
    }

    .stores_div #stores_contact_info_p {
        margin: 3vw 0 0 0;
    }

/*  md size */
@media only screen and (max-width: 767px) {

    .stores_div img {
        max-width: 60%;
    }

    .stores_div #stores_contact_info_p {
        margin: 10vw 0 0 0;
    }

}